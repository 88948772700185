#Ranks {
    min-height: 60vh;
}

#Ranks-Table {
    background: #151515;
    color: #cccccc;
    margin: 0;
    padding: 0;
    margin-bottom: 5%;
}

.Ranks-TH {
    color: #cccccc !important;
    font-weight: 800;
}

.Ranks-SettingsButton {
    font-size: 14px;
    width: 120px;
    padding: 8px;
    background: orange !important;
}

.Ranks-TiersButton {
    font-size: 14px;
    width: 200px;
    padding: 8px;
    background: orange !important;
}

.Ranks-TD-Username-Image {
    width: 35px;
    position: relative;
    margin-right: 20px;
    margin-top: -8px;
    top: 3px;
}

.Ranks-TD-Username {
    font-weight: 600;
}

.Ranks-TD-Rank {
    font-weight: 400;
}
