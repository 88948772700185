#SinglePlaylist {
    min-height: 59.9vh;
    background: #151515;
}

.SinglePlaylist-Container {
    margin-top: 20px;
    margin-left: 10px;
}

.SinglePlaylist-Breadcrumb {
    padding: 0;
}

#SinglePlaylist-Table {
    background: #151515;
    color: #cccccc;
    margin: 0;
    padding: 0;
    margin-bottom: 5%;
}

.SinglePlaylist-TH {
    color: #cccccc !important;
    font-weight: 800;
}

.SinglePlaylist-Number {
    font-weight: 600;
}

.SinglePlaylist-TD-Title {
    font-weight: 600;
}

.SinglePlaylist-TD-Author {
    font-weight: 600;
}

.SinglePlaylist-TD-Link {
    font-weight: 400;
    color: #cccccc !important;
    text-decoration: none;
    transition: .2s;
}

.SinglePlaylist-TD-Link:active, .SinglePlaylist-TD-Link:visited {
    text-decoration: none !important;
    color: #cccccc !important;
}

.SinglePlaylist-TD-Link:hover {
    cursor: pointer;
    color: orange !important;
}

.SinglePlaylist-Icon-Trash {
    transition: .3s;
}

.SinglePlaylist-Icon-Trash:hover {
    color: orange;
    cursor: pointer;
}