.HomePage-Logo_Text { 
    font-family: 'NexaRustSlab', sans-serif; 
    display: inline-block; 
    position: relative;
    top: 35px;
    left: -5%;
    font-size: 40px;
    margin-bottom: 3%;
}

.HomePage-Logo {
    position: relative;
    width: 140px;
    display: inline-block;
    margin-bottom: 1%;
    left: -3%;
}

@media (max-width: 800px) {
    .HomePage-Logo {
        width: 100px;
        display: inline-block;
        margin-bottom: 6%;
    }

    .HomePage-Button {
        margin-top: 0;
    }

    .HomePage-Logo_Text { 
        font-family: 'NexaRustSlab', sans-serif; 
        display: inline-block; 
        position: relative;
        top: 12px;
        left: -20px;
        font-size: 25px;
        margin-bottom: 3%;
    }
}