#Economy {
    min-height: 60vh;
};

#Economy-Table {
    background: #151515 !important;
    color: #cccccc;
    margin: 0;
    padding: 0;
    margin-bottom: 5%;
}

.Economy-TH {
    color: #cccccc !important;
    font-weight: 800;
}

.Economy-SettingsButton {
    font-size: 14px;
    width: 120px;
    padding: 8px;
    background: orange !important;
}

.Economy-TD-Username {
    font-weight: 600;
}

.Economy-TD-Username-Image {
    width: 35px;
    position: relative;
    margin-right: 20px;
    margin-top: -8px;
    top: 3px;
}

.Economy-TD-Amount {
    font-weight: 400;
}