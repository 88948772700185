#FAQ {
    min-height: 60vh;
}

.md-accordion .md-accordion {
    border-color: #3c3c3c !important;
}

.FAQ-CardBody {
    color: #cccccc !important;
    font-size: 14px;
}