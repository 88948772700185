#Trackers {
    min-height: 62vh;
};

/* Trackers-ContainerMain needs to stay for Table Styling to work*/
#Trackers-ContainerMain {
    background: transparent;
}

#Trackers-Table {
    background: #151515;
    color: #cccccc;
    margin: 0;
    padding: 0;
    margin-bottom: 5%;
}

.Trackers-TH {
    color: #cccccc !important;
    font-weight: 800;
}

.Trackers-TD-Username {
    font-weight: 600;
}

.Trackers-TD-Channel {
    font-weight: 400;
}

.Trackers-TD-Role {
    font-weight: 400;
}

.Trackers-Icon-Trash {
    transition: .3s;
}
.Trackers-Icon-Trash:hover { color: orange }
.Trackers-TD-Action {
    width: 20%;
}