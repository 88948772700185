@import url('https://fonts.googleapis.com/css?family=Raleway'); /* Raleway Font Import */
@import url('https://fonts.googleapis.com/css?family=Roboto'); /* Roboto Font Import */

@font-face {
  font-family: 'NexaRustSlab';
  src: url(./res/fonts/NexaRustSlab.otf);
}

@font-face {
  font-family: 'Risa';
  src: url(./res/fonts/Risa.otf);
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #151515;
  color: #cccccc;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a{
  text-decoration: none;
  color: inherit;
}

a:visited, a:hover, a:focus, a:active {
  text-decoration: none;
  color: inherit;
}

table.table a {
  color: inherit;
}

body::-webkit-scrollbar { background: #0d0d0d; }
body::-webkit-scrollbar-thumb { background: #262626; }

.display-inline { display: inline; }
.display-none { display: none; }

.Discord-Button {
  background: #4a67cf !important;
  color: #cccccc;
  transition: .5s;
}
.Discord-Button:hover {
  background: #4a67cfc2 !important;
  color: #cccccc;
}
.Button { 
  background: #A42700 !important; 
  color: white;
  transition: .5s;
}
.Button:hover {
  background-color: rgba(164, 38, 0, 0.932) !important;
  color: #cccccc;
}

.FontAwesomeIcon { display: inline; margin-right: 5%; }
.Component-Header {
  font-size: 2.5rem;
  font-weight: 600;
}
.Component-Breadcrumb {
  display: inline;
  transition: .3s;
}

.Component-Breadcrumb:hover {
  color: #A42700;
  cursor: pointer;
}

.Component-Breadcrumb-Main {
  font-weight: 600;
}

.Component-Content {
  margin-top: 25px;
}

.Modal {
  background: #151515 !important;
  color: #cccccc;
  border-color: #0d0d0d;

}

.Modal > .close {
  color: #cccccc;
}

.modal-content {
  background-color: #151515 !important;
}

.cascading-admin-card .admin-up {
  margin-left: 4%;
  margin-right: 4%;
  margin-top: -20px;
}

.cascading-admin-card .admin-up .Admin-FontAwesomeIcon {
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.2), 0 2px 13px 0 rgba(0, 0, 0, 0.19);
}

.cascading-admin-card .admin-up .Admin-FontAwesomeIcon {
  font-size: 65px;
  color: #cccccc;
  text-align: left;
  margin-right: 1rem;
  border-radius: 3px;
}

.Admin-FontAwesomeIcon {
  padding: 15px;
}

.cascading-admin-card .admin-up .data {
  float: right;
  margin-top: 2rem;
  text-align: right;
}
.admin-up .data p {
  color: #999999;
  font-size: 12px;
}
.classic-admin-card .card-body {
  color: #fff;
  margin-bottom: 0;
  padding: 0.9rem;
}
.classic-admin-card .card-body p {
  font-size: 13px;
  opacity: 0.7;
  margin-bottom: 0;
}
.classic-admin-card .card-body h4 {
  margin-top: 10px;
}

.progress {
  height: 12px;
  background: #1f1f1f !important;
}

.orange {
  background: #FF5005 !important;
}

.cyan {
  background: #00B39A !important;
}

.yellow {
  background: #CC8218 !important;
}

.carousel-multi-item .carousel-indicators li {
  background-color: #A42700;
}

.carousel-multi-item .carousel-indicators .active {
  background-color: #A42700;
}
