.avatar-test {
    background: inherit !important;
}

.avatar-test-modal {
    background: inherit !important;
}

.carousel-multi-item .carousel-indicators li {
    background-color: #A42700;
}
  
.carousel-multi-item .carousel-indicators .active {
    background-color: #A42700;
}

.btn-floating {
    background: #A42700 !important;
}

.Modal-Header {
    background: rgb(164, 39, 0, .7) !important;
    margin-bottom: 5%;
}

@media (max-width: 1280px) {
    .avatar-test {
        padding-left: 20px;
    }
}

@media (max-width: 850px) {
    .avatar-test {
        position: relative;
        padding-left: 0;
        left: -20px;
    }
    .helloWorld {
        display: none;
    }
}

@media (max-width: 750px) {
    .avatar-test {
        padding-left: 30px;
    }
    .display-md-none {
        display: none;
    }
}

@media (max-width: 700px) {
    .avatar-test {
        padding-left: 15px;
    }
    .d-md-none {
        display: none;
    }
}