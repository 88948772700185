.side-nav .logo-wrapper {
    height: 150px;
    position: relative;
    top: -30px;
}

.side-nav .logo-wrapper > a {
    height: 142px;
}

.SideNav-El {
    font-weight: 600 !important;
}

.SideNav-El:hover {
    background: rgba(64, 64, 64, .5) !important;
}

.SideNav-El.active {
    background: rgba(38, 38, 38, .5) !important;
}

.SideNav-Dropdown-El {
    font-weight: 600 !important;
}

.SideNav-TopNav__DropDownMenu {
    background: #3B3B3B;
    border-color: #3B3B3B;
}

.SideNav-TopNav-Dropdown-Item-Span {
    color: #cccccc;
    font-weight: 600;
}

.SideNav-TopNav-Dropdown-Item:hover {
    background: #1a1a1a !important;
}

.dropdown-item:hover {
    background: #1a1a1a !important;
}

.black-skin .side-nav .collapsible li a:not(.collapsible-header):hover, 
.black-skin .side-nav .collapsible li a:not(.collapsible-header).active, 
.black-skin .side-nav .collapsible li a:not(.collapsible-header):active {
    color: orange !important;
}

.dropdown .dropdown-menu.dropdown-default .dropdown-item:hover, 
.dropdown .dropdown-menu.dropdown-default .dropdown-item:active, 
.dropdown .dropdown-menu.dropdown-default .dropdown-item.active, 
.dropup .dropdown-menu.dropdown-default .dropdown-item:hover, 
.dropup .dropdown-menu.dropdown-default .dropdown-item:active, 
.dropup .dropdown-menu.dropdown-default .dropdown-item.active, 
.dropleft .dropdown-menu.dropdown-default .dropdown-item:hover, 
.dropleft .dropdown-menu.dropdown-default .dropdown-item:active, 
.dropleft .dropdown-menu.dropdown-default .dropdown-item.active, 
.dropright .dropdown-menu.dropdown-default .dropdown-item:hover, 
.dropright .dropdown-menu.dropdown-default .dropdown-item:active, 
.dropright .dropdown-menu.dropdown-default .dropdown-item.active {
    background: #1a1a1a !important;
}

.black-skin .navbar .navbar-nav .nav-item .dropdown-menu a:hover, 
.black-skin .navbar .navbar-nav .nav-item .dropdown-menu a:focus, 
.black-skin .navbar .navbar-nav .nav-item .dropdown-menu a:active {
    background: transparent !important;
}