#EconomySettings-Container {
    margin-top: 20px;
    margin-left: 10px;
}

#EconomySettings-CurrencyName, #EconomySettings-CurrencyIncreaseRate {
    background: inherit;
    border: none;
    border-bottom: solid #cccccc;
    border-radius: 0px;
}

#EconomySettings-UpdateButton {
    border:none;
    border-left: solid #cccccc;
    background: inherit !important;
    padding: 5px 8px 5px 8px;
    font-size: 15px;
}

#EconomySettings-UpdateButton:hover {
    transition: .5s;
    color: orange !important;
    border-left: solid orange;
}

#EconomySettings-CurrencyName-Col > div {
    margin: 0;
}

#EconomySettings-CurrencyName-Col > div > input {
    padding: 0;
}

#EconomySettings-CurrencyIncreaseRate-Col > div > input {
    border: none;
    margin-top: 10px;
}