@import url(https://fonts.googleapis.com/css?family=Raleway);
@import url(https://fonts.googleapis.com/css?family=Roboto);
/* Raleway Font Import */ /* Roboto Font Import */

@font-face {
  font-family: 'NexaRustSlab';
  src: url(/static/media/NexaRustSlab.2024a8a8.otf);
}

@font-face {
  font-family: 'Risa';
  src: url(/static/media/Risa.9e1c402a.otf);
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #151515;
  color: #cccccc;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a{
  text-decoration: none;
  color: inherit;
}

a:visited, a:hover, a:focus, a:active {
  text-decoration: none;
  color: inherit;
}

table.table a {
  color: inherit;
}

body::-webkit-scrollbar { background: #0d0d0d; }
body::-webkit-scrollbar-thumb { background: #262626; }

.display-inline { display: inline; }
.display-none { display: none; }

.Discord-Button {
  background: #4a67cf !important;
  color: #cccccc;
  transition: .5s;
}
.Discord-Button:hover {
  background: #4a67cfc2 !important;
  color: #cccccc;
}
.Button { 
  background: #A42700 !important; 
  color: white;
  transition: .5s;
}
.Button:hover {
  background-color: rgba(164, 38, 0, 0.932) !important;
  color: #cccccc;
}

.FontAwesomeIcon { display: inline; margin-right: 5%; }
.Component-Header {
  font-size: 2.5rem;
  font-weight: 600;
}
.Component-Breadcrumb {
  display: inline;
  transition: .3s;
}

.Component-Breadcrumb:hover {
  color: #A42700;
  cursor: pointer;
}

.Component-Breadcrumb-Main {
  font-weight: 600;
}

.Component-Content {
  margin-top: 25px;
}

.Modal {
  background: #151515 !important;
  color: #cccccc;
  border-color: #0d0d0d;

}

.Modal > .close {
  color: #cccccc;
}

.modal-content {
  background-color: #151515 !important;
}

.cascading-admin-card .admin-up {
  margin-left: 4%;
  margin-right: 4%;
  margin-top: -20px;
}

.cascading-admin-card .admin-up .Admin-FontAwesomeIcon {
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.2), 0 2px 13px 0 rgba(0, 0, 0, 0.19);
}

.cascading-admin-card .admin-up .Admin-FontAwesomeIcon {
  font-size: 65px;
  color: #cccccc;
  text-align: left;
  margin-right: 1rem;
  border-radius: 3px;
}

.Admin-FontAwesomeIcon {
  padding: 15px;
}

.cascading-admin-card .admin-up .data {
  float: right;
  margin-top: 2rem;
  text-align: right;
}
.admin-up .data p {
  color: #999999;
  font-size: 12px;
}
.classic-admin-card .card-body {
  color: #fff;
  margin-bottom: 0;
  padding: 0.9rem;
}
.classic-admin-card .card-body p {
  font-size: 13px;
  opacity: 0.7;
  margin-bottom: 0;
}
.classic-admin-card .card-body h4 {
  margin-top: 10px;
}

.progress {
  height: 12px;
  background: #1f1f1f !important;
}

.orange {
  background: #FF5005 !important;
}

.cyan {
  background: #00B39A !important;
}

.yellow {
  background: #CC8218 !important;
}

.carousel-multi-item .carousel-indicators li {
  background-color: #A42700;
}

.carousel-multi-item .carousel-indicators .active {
  background-color: #A42700;
}

/* #NavBar {
    background: transparent;
    width: 100%;
}

.NavBar-Element {
    padding: 10px;
}

.NavBar-Element:hover { color: white; }

.NavBar-Login {
    font-size: 10.1px;
    width: 19vh;
    font-weight: 600;
} */
.side-nav .logo-wrapper {
    height: 150px;
    position: relative;
    top: -30px;
}

.side-nav .logo-wrapper > a {
    height: 142px;
}

.SideNav-El {
    font-weight: 600 !important;
}

.SideNav-El:hover {
    background: rgba(64, 64, 64, .5) !important;
}

.SideNav-El.active {
    background: rgba(38, 38, 38, .5) !important;
}

.SideNav-Dropdown-El {
    font-weight: 600 !important;
}

.SideNav-TopNav__DropDownMenu {
    background: #3B3B3B;
    border-color: #3B3B3B;
}

.SideNav-TopNav-Dropdown-Item-Span {
    color: #cccccc;
    font-weight: 600;
}

.SideNav-TopNav-Dropdown-Item:hover {
    background: #1a1a1a !important;
}

.dropdown-item:hover {
    background: #1a1a1a !important;
}

.black-skin .side-nav .collapsible li a:not(.collapsible-header):hover, 
.black-skin .side-nav .collapsible li a:not(.collapsible-header).active, 
.black-skin .side-nav .collapsible li a:not(.collapsible-header):active {
    color: orange !important;
}

.dropdown .dropdown-menu.dropdown-default .dropdown-item:hover, 
.dropdown .dropdown-menu.dropdown-default .dropdown-item:active, 
.dropdown .dropdown-menu.dropdown-default .dropdown-item.active, 
.dropup .dropdown-menu.dropdown-default .dropdown-item:hover, 
.dropup .dropdown-menu.dropdown-default .dropdown-item:active, 
.dropup .dropdown-menu.dropdown-default .dropdown-item.active, 
.dropleft .dropdown-menu.dropdown-default .dropdown-item:hover, 
.dropleft .dropdown-menu.dropdown-default .dropdown-item:active, 
.dropleft .dropdown-menu.dropdown-default .dropdown-item.active, 
.dropright .dropdown-menu.dropdown-default .dropdown-item:hover, 
.dropright .dropdown-menu.dropdown-default .dropdown-item:active, 
.dropright .dropdown-menu.dropdown-default .dropdown-item.active {
    background: #1a1a1a !important;
}

.black-skin .navbar .navbar-nav .nav-item .dropdown-menu a:hover, 
.black-skin .navbar .navbar-nav .nav-item .dropdown-menu a:focus, 
.black-skin .navbar .navbar-nav .nav-item .dropdown-menu a:active {
    background: transparent !important;
}
.ServerPicker-GuildIcon {
    width: 35px;
    margin-right: 5%;
}

.ServerPicker-Dropdown-Item-Span {
    color: #cccccc;
    font-weight: 600;
}

.ServerPicker-DropDown {
    width: 200px
}

.ServerPicker__DropDownMenu {
    background: #3B3B3B;
    border-color: #3B3B3B;
}

.dropdown-item:hover {
    background: #1a1a1a !important;
}

.ServerPicker-ChosenGuild-GuildIcon {
    height: 18px;
    margin-right: 5%;
    margin-top: -4px;
}
#Dashboard {
    min-height: 55vh;
}

.Dashboard-Card {
    background: #363636;
    color: #cccccc;
}
@media (max-width: 800px) {
    .WeeklyWrapup-Col {
        margin-bottom: 5%;
    }
}

#Analytics {
    min-height: 55vh;
}





#CustomCommands {
    min-height: 62vh;
}

#CustomCommands-Table {
    background: #151515;
    color: #cccccc;
    margin: 0;
    padding: 0;
    margin-bottom: 5%;
}

.CustomCommands-TH {
    color: #cccccc !important;
    font-weight: 800;
}

.CustomCommands-TD {
    font-weight: 600;
}

.CustomCommands-TD-Username-Image {
    width: 35px;
    position: relative;
    margin-right: 20px;
    margin-top: -8px;
    top: 3px;
}
#AddCustomCommand-Input-Col > div, #AddCustomCommand-Output-Col > div {
    margin: 0;
}

#AddCustomCommand-Input-Col > div > input, #AddCustomCommand-Output-Col > div > input {
    padding: 0;
}
#EditCustomCommand-Input-Col > div, #EditCustomCommand-Output-Col > div {
    margin: 0;
}

#EditCustomCommand-Input-Col > div > input, #EditCustomCommand-Output-Col > div > input {
    padding: 0;
}
#Ranks {
    min-height: 60vh;
}

#Ranks-Table {
    background: #151515;
    color: #cccccc;
    margin: 0;
    padding: 0;
    margin-bottom: 5%;
}

.Ranks-TH {
    color: #cccccc !important;
    font-weight: 800;
}

.Ranks-SettingsButton {
    font-size: 14px;
    width: 120px;
    padding: 8px;
    background: orange !important;
}

.Ranks-TiersButton {
    font-size: 14px;
    width: 200px;
    padding: 8px;
    background: orange !important;
}

.Ranks-TD-Username-Image {
    width: 35px;
    position: relative;
    margin-right: 20px;
    margin-top: -8px;
    top: 3px;
}

.Ranks-TD-Username {
    font-weight: 600;
}

.Ranks-TD-Rank {
    font-weight: 400;
}

#RankTiers {
    min-height: 62vh;
}

.RankTiers-Breadcrumb {
    padding: 0;
}

#RankTiers-Table {
    background: #151515;
    color: #cccccc;
    margin: 0;
    padding: 0;
    margin-bottom: 5%;
}

.RankTiers-TH {
    color: #cccccc !important;
    font-weight: 800;
}

.RankTiers-TD-RankNumber {
    font-weight: 800;
}

.RankTiers-TD-RankName {
    font-weight: 600;
}

.RankTiers-TD-Rank {
    font-weight: 400;
}
#AddRank-RankName-Col > div {
    margin: 0;
}

#AddRank-RankName-Col > div > input {
    padding: 0;
}
#RankSettings-GeneralIncreaseRate-Col > div > input, #RankSettings-Complexity-Col > div > input {
    border: none;
    margin-top: 10px;
}

#PlaylistSelect {
    min-height: 62vh;
    display: flex;
    align-items: center;
}

.PlaylistSelect-Dropdown-Item-Span {
    color: #cccccc;
    font-weight: 600;
}

.PlaylistSelect-DropDown {
    width: 500px
}

.PlaylistSelect__DropDownMenu {
    background: #3B3B3B;
    border-color: #3B3B3B;
    width: 500px;
}

.dropdown-item:hover {
    background: #1a1a1a !important;
}
.AutoDJQueue-Table {
    color: #cccccc;
}

.AutoDJQueue-TableHeaders {
}

.AutoDJQueue-TableRows {

}
.AutoDJPlayer-Play {
    font-size: 60px;
    margin-right: 10px;
}

.AutoDJPlayer-Next {
    position: relative;
    font-size: 40px;
    top: -8px;
}

.AutoDJPlayer-Controls:hover {
    color: orange;
}

.range-field input[type="range"] {
    border: none;
    margin: 3px 0;
}

.range-field input[type="range"] + .thumb {
    background: orange !important;
}

div .range-field input[type="range"] + .thumb .value, div .range-field input[type="range"] + .thumb.active .value {
    font-size: 12px;
}


#Playlists {
    height: 50vh;
    width: 100%;
    background: #151515;
};

#Playlists-ContainerMain {
    background: transparent;
}

.Playlists-Container {
    margin-top: 280px;
}

#Playlists-MyPlaylists, #Playlists-GuildPlaylists {
    background: #363636 !important;
    border: solid #363636;
    font-weight: 600;
    text-align: center;
    padding: 20px 80px 20px 80px;
    
    border-radius: 2px;
    transition: .5s;
}

#Playlists-MyPlaylists:hover, #Playlists-GuildPlaylists:hover {
    border: medium solid orange;
    color: orange;
}

#Playlists-MyPlaylists {

}

#Playlists-GuildPlaylists {

}
#SinglePlaylist {
    min-height: 59.9vh;
    background: #151515;
}

.SinglePlaylist-Container {
    margin-top: 20px;
    margin-left: 10px;
}

.SinglePlaylist-Breadcrumb {
    padding: 0;
}

#SinglePlaylist-Table {
    background: #151515;
    color: #cccccc;
    margin: 0;
    padding: 0;
    margin-bottom: 5%;
}

.SinglePlaylist-TH {
    color: #cccccc !important;
    font-weight: 800;
}

.SinglePlaylist-Number {
    font-weight: 600;
}

.SinglePlaylist-TD-Title {
    font-weight: 600;
}

.SinglePlaylist-TD-Author {
    font-weight: 600;
}

.SinglePlaylist-TD-Link {
    font-weight: 400;
    color: #cccccc !important;
    text-decoration: none;
    transition: .2s;
}

.SinglePlaylist-TD-Link:active, .SinglePlaylist-TD-Link:visited {
    text-decoration: none !important;
    color: #cccccc !important;
}

.SinglePlaylist-TD-Link:hover {
    cursor: pointer;
    color: orange !important;
}

.SinglePlaylist-Icon-Trash {
    transition: .3s;
}

.SinglePlaylist-Icon-Trash:hover {
    color: orange;
    cursor: pointer;
}
#AddSong-Request-Col > div > input {
    padding: 0;
}
#UserPlaylists {
    min-height: 55vh;
    background: #151515;
};

.UserPlaylists-Card {
    background: #363636;
    color: #cccccc;
}

.UserPlaylists-Trash {
    transition: .5s;
}

.UserPlaylists-Trash:hover {
    color: orange;
}
#AddPlaylist-Name-Col > div {
    margin: 0;
}

#AddPlaylist-Name-Col > div > input {
    padding: 0;
}
#GuildPlaylists {
    min-height: 62vh;
}
#Economy {
    min-height: 60vh;
};

#Economy-Table {
    background: #151515 !important;
    color: #cccccc;
    margin: 0;
    padding: 0;
    margin-bottom: 5%;
}

.Economy-TH {
    color: #cccccc !important;
    font-weight: 800;
}

.Economy-SettingsButton {
    font-size: 14px;
    width: 120px;
    padding: 8px;
    background: orange !important;
}

.Economy-TD-Username {
    font-weight: 600;
}

.Economy-TD-Username-Image {
    width: 35px;
    position: relative;
    margin-right: 20px;
    margin-top: -8px;
    top: 3px;
}

.Economy-TD-Amount {
    font-weight: 400;
}
#EconomySettings-Container {
    margin-top: 20px;
    margin-left: 10px;
}

#EconomySettings-CurrencyName, #EconomySettings-CurrencyIncreaseRate {
    background: inherit;
    border: none;
    border-bottom: solid #cccccc;
    border-radius: 0px;
}

#EconomySettings-UpdateButton {
    border:none;
    border-left: solid #cccccc;
    background: inherit !important;
    padding: 5px 8px 5px 8px;
    font-size: 15px;
}

#EconomySettings-UpdateButton:hover {
    transition: .5s;
    color: orange !important;
    border-left: solid orange;
}

#EconomySettings-CurrencyName-Col > div {
    margin: 0;
}

#EconomySettings-CurrencyName-Col > div > input {
    padding: 0;
}

#EconomySettings-CurrencyIncreaseRate-Col > div > input {
    border: none;
    margin-top: 10px;
}
#Moderation {
    min-height: 62vh;
}
#SendEmbed-Col > div {
    margin: 0;
}

#SendEmbed-Col > div > input {
    padding: 0;
    border: 2px solid rgb(204, 204, 204, .3);
    border-radius: 4px;
}

#SendEmbed-Col > div > input:focus {
    border: 2px solid rgb(255, 165, 0, .6);
}

.SendEmbed-TextArea {
    background: #1a1a1a !important;
    border: solid thin #0a0a0a !important;
    color: #cccccc !important;
    height: auto;
}

.SendEmbed-Textarea-Prepend {
    background: #151515 !important;
    color: #cccccc !important;
    border: solid thin #0a0a0a!important;
}

#SendEmbed-ChannelSelect {
    border: solid 2px rgb(204, 204, 204, .3);
    border-radius: 4px;
    margin: .375rem;
}

.md-form input:not([type]):focus:not([readonly]), .md-form input[type="text"]:not(.browser-default):focus:not([readonly]), 
.md-form input[type="password"]:not(.browser-default):focus:not([readonly]), .md-form input[type="email"]:not(.browser-default):focus:not([readonly]), 
.md-form input[type="url"]:not(.browser-default):focus:not([readonly]), .md-form input[type="time"]:not(.browser-default):focus:not([readonly]), 
.md-form input[type="date"]:not(.browser-default):focus:not([readonly]), .md-form input[type="datetime"]:not(.browser-default):focus:not([readonly]), 
.md-form input[type="datetime-local"]:not(.browser-default):focus:not([readonly]), .md-form input[type="tel"]:not(.browser-default):focus:not([readonly]), 
.md-form input[type="number"]:not(.browser-default):focus:not([readonly]), .md-form input[type="search"]:not(.browser-default):focus:not([readonly]), 
.md-form input[type="search-md"]:focus:not([readonly]), .md-form textarea.md-textarea:focus:not([readonly]) {
    box-shadow: none;
    color: #cccccc;
}

.caret {
    padding-right: 15px;
}

#SendEmbed-ChannelSelect input[type="text"]:not(.browser-default) {
    border-bottom: none;
    margin-bottom: 0;
    padding: 0 10px 0 10px;
}

.select-wrapper:not(.md-outline) .select-dropdown:focus {
    border-bottom: transparent !important;
}

.switch label .lever {
    background: #3c3c3c;
}

.switch label input[type="checkbox"]:checked + .lever {
    background: #5c5c5c;
}


.switch label input[type="checkbox"]:checked + .lever::after {
    background: rgba(164, 38, 0, 0.932);
}

.switch label .lever::after {
    background: #555555;
}
#EmbedPreview {
    display: flex;
    flex-direction: column;
}

#wrapper {
    display: flex;
    max-width: 520px;
}

#side-color {
    width: 4px;
    border-radius: 3px 0 0 3px;
}

#card {
    background: rgba(48, 50, 53, 0.3);
    border-color: rgba(87, 90, 102, 0.6);
    color: rgba(255, 255, 255, .6);
    border-radius: 0 3px 3px 0;
    display: flex;
    padding: 8px 10px;
    font-size: 14px;
    position: relative;
    flex-direction: column;
    border: 1px solid rgba(0,0,0,.125)
}

.block {
    padding: 0;
    display: flex;
    margin-bottom: 10px;
    flex: 1 1 auto;
    -webkit-box-flex: 1;
}

#inner #fields .field {
    flex: 0 1;
    padding-top: 10px;
    max-width: 506px;
    min-width: 100%;
}

#inner #fields {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: -10px;
}

#inner #author {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

#inner #author img#author-icon {
    margin-right: 9px;
    width: 20px;
    height: 20px;
    object-fit: contain;
    border-radius: 50%;
}

#inner #author #author-name {
    color: white;
    display: inline-block;
    font-weight: 600;
    font-size: 14px;
}

#title {
    color: white;
}

#inner #fields .field .field-name {
    color: white;
    font-size: 14px;
    margin-bottom: 4px;
    font-weight: 800;
}

#inner #fields .field .field-value {
    color: white;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.1em;
    white-space: pre-wrap;
    margin-top: 6px;
    word-wrap: break-word;
}

#inner #fields .field.inline {
    box-sizing: 2;
    -webkit-box-sizing: 2;
    flex: 2 1;
    min-width: 135px;
    flex-basis: auto;
}

#thumb {
    height: 684px;
}

img#thumb {
    max-height: 80px;
    max-width: 80px;
    border-radius: 3px;
    flex-shrink: 0;
    width: auto;
    object-fit: contain;
    margin-left: 20px;
}

#footer {
    font-size: 12px;
}

#footer-name {
    color: rgba(255, 255, 255, 0.6);
    box-sizing: inherit;
}

#footer #footer-icon {
    margin-right: 9px;
    width: 20px;
    height: 20px;
    object-fit: contain;
    border-radius: 50%;
}
.chrome-picker {
    background: #2c2c2c !important;
    
}

.chrome-picker > div > div > div > div > div > input {
    color: #cccccc !important;
    background: #0d0d0d;
    box-shadow: none !important;
}
#Trackers {
    min-height: 62vh;
};

/* Trackers-ContainerMain needs to stay for Table Styling to work*/
#Trackers-ContainerMain {
    background: transparent;
}

#Trackers-Table {
    background: #151515;
    color: #cccccc;
    margin: 0;
    padding: 0;
    margin-bottom: 5%;
}

.Trackers-TH {
    color: #cccccc !important;
    font-weight: 800;
}

.Trackers-TD-Username {
    font-weight: 600;
}

.Trackers-TD-Channel {
    font-weight: 400;
}

.Trackers-TD-Role {
    font-weight: 400;
}

.Trackers-Icon-Trash {
    transition: .3s;
}
.Trackers-Icon-Trash:hover { color: orange }
.Trackers-TD-Action {
    width: 20%;
}
.AddTracker-GuildIcon {
    width: 35px;
    margin-right: 5%;
}

.AddTracker-Dropdown-Item-Span {
    color: #cccccc;
    font-weight: 600;
}

.AddTracker-DropDown {
    width: 200px
}

.dropdown-content {
    background: #3B3B3B;
    border-color: #3B3B3B;
}

.dropdown-content li:hover, .dropdown-content li.active {
    background: #1a1a1a;
}

.dropdown-content li > a, .dropdown-content li > span {
    color: #cccccc;
    font-weight: 600;
}

#AddTracker-Input-Col > div, #AddTracker-Output-Col > div {
    margin: 0;
}

.caret { color: #cccccc !important; }
.select-dropdown { color: #cccccc !important; }
.select-dropdown li.disabled, .select-dropdown li.disabled > span, .select-dropdown li.optgroup {
    color: orange;
    font-weight: 600;
}

#Settings {
    min-height: 60vh;
}
#GuildSettings-Prefix-Col > div {
    margin: 0;
}

#GuildSettings-Prefix-Col > div > input {
    padding: 0;
}

.UserSettings_Avatar {
    height: 120px !important;
    width: 120px !important;
    border-color: #3c3c3c !important;
    background: #3c3c3c !important;
}

.UserSettings_Username {
    position: relative;
    top: 35px;
    left: 20px;
    font-weight: 600;
}

.testimonial-card .card-up {
    height: 160px;
}

.testimonial-card .avatar {
    margin-top: -60px !important;
}


#Support {
    min-height: 62vh;
}

.HomePage-Logo_Text { 
    font-family: 'NexaRustSlab', sans-serif; 
    display: inline-block; 
    position: relative;
    top: 35px;
    left: -5%;
    font-size: 40px;
    margin-bottom: 3%;
}

.HomePage-Logo {
    position: relative;
    width: 140px;
    display: inline-block;
    margin-bottom: 1%;
    left: -3%;
}

@media (max-width: 800px) {
    .HomePage-Logo {
        width: 100px;
        display: inline-block;
        margin-bottom: 6%;
    }

    .HomePage-Button {
        margin-top: 0;
    }

    .HomePage-Logo_Text { 
        font-family: 'NexaRustSlab', sans-serif; 
        display: inline-block; 
        position: relative;
        top: 12px;
        left: -20px;
        font-size: 25px;
        margin-bottom: 3%;
    }
}
.Offers-Icon {
    font-size: 35px;
}
#Features {
    min-height: 55vh;
}
#Testimonials {
    min-height: 60vh;
}
.avatar-test {
    background: inherit !important;
}

.avatar-test-modal {
    background: inherit !important;
}

.carousel-multi-item .carousel-indicators li {
    background-color: #A42700;
}
  
.carousel-multi-item .carousel-indicators .active {
    background-color: #A42700;
}

.btn-floating {
    background: #A42700 !important;
}

.Modal-Header {
    background: rgb(164, 39, 0, .7) !important;
    margin-bottom: 5%;
}

@media (max-width: 1280px) {
    .avatar-test {
        padding-left: 20px;
    }
}

@media (max-width: 850px) {
    .avatar-test {
        position: relative;
        padding-left: 0;
        left: -20px;
    }
    .helloWorld {
        display: none;
    }
}

@media (max-width: 750px) {
    .avatar-test {
        padding-left: 30px;
    }
    .display-md-none {
        display: none;
    }
}

@media (max-width: 700px) {
    .avatar-test {
        padding-left: 15px;
    }
    .d-md-none {
        display: none;
    }
}
#Premium {
    min-height: 55vh;
}
#FAQ {
    min-height: 60vh;
}

.md-accordion .md-accordion {
    border-color: #3c3c3c !important;
}

.FAQ-CardBody {
    color: #cccccc !important;
    font-size: 14px;
}
.Footer-Logo-Text {
    position: relative;
    font-family: 'NexaRustSlab', sans-serif; 
    display: inline-block;
    top: 5px;
    left: -10px;
}

.Footer-Logo {
    width: 40px;
    display: inline-block;
    margin-bottom: 1%;
    margin-right: 1%;
}

.Footer-LI {
    font-size: 14px;
}

.Footer-Link:hover {
    color: orange;
}

.Footer-Icon {
    font-size: 20px;
    display: inline-block;
    margin-right: 12px;
}

.Footer-Col {
    margin-bottom: 2%;
}

.Footer-Col > p {
    display: inline;
}

.Footer-Fireside {
    color: orange;
    display: inline;
}

#Footer-DBL-Link { color: orange }

@media (max-width: 800px) {
    .Footer-Col__Sec1 {
        margin-bottom: 5%;
    }
}

#ChangeLogs {
    min-height: 70vh;
}

.React-Markdown {
    color: #cccccc;
}

