.ServerPicker-GuildIcon {
    width: 35px;
    margin-right: 5%;
}

.ServerPicker-Dropdown-Item-Span {
    color: #cccccc;
    font-weight: 600;
}

.ServerPicker-DropDown {
    width: 200px
}

.ServerPicker__DropDownMenu {
    background: #3B3B3B;
    border-color: #3B3B3B;
}

.dropdown-item:hover {
    background: #1a1a1a !important;
}

.ServerPicker-ChosenGuild-GuildIcon {
    height: 18px;
    margin-right: 5%;
    margin-top: -4px;
}