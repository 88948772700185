#SendEmbed-Col > div {
    margin: 0;
}

#SendEmbed-Col > div > input {
    padding: 0;
    border: 2px solid rgb(204, 204, 204, .3);
    border-radius: 4px;
}

#SendEmbed-Col > div > input:focus {
    border: 2px solid rgb(255, 165, 0, .6);
}

.SendEmbed-TextArea {
    background: #1a1a1a !important;
    border: solid thin #0a0a0a !important;
    color: #cccccc !important;
    height: auto;
}

.SendEmbed-Textarea-Prepend {
    background: #151515 !important;
    color: #cccccc !important;
    border: solid thin #0a0a0a!important;
}

#SendEmbed-ChannelSelect {
    border: solid 2px rgb(204, 204, 204, .3);
    border-radius: 4px;
    margin: .375rem;
}

.md-form input:not([type]):focus:not([readonly]), .md-form input[type="text"]:not(.browser-default):focus:not([readonly]), 
.md-form input[type="password"]:not(.browser-default):focus:not([readonly]), .md-form input[type="email"]:not(.browser-default):focus:not([readonly]), 
.md-form input[type="url"]:not(.browser-default):focus:not([readonly]), .md-form input[type="time"]:not(.browser-default):focus:not([readonly]), 
.md-form input[type="date"]:not(.browser-default):focus:not([readonly]), .md-form input[type="datetime"]:not(.browser-default):focus:not([readonly]), 
.md-form input[type="datetime-local"]:not(.browser-default):focus:not([readonly]), .md-form input[type="tel"]:not(.browser-default):focus:not([readonly]), 
.md-form input[type="number"]:not(.browser-default):focus:not([readonly]), .md-form input[type="search"]:not(.browser-default):focus:not([readonly]), 
.md-form input[type="search-md"]:focus:not([readonly]), .md-form textarea.md-textarea:focus:not([readonly]) {
    box-shadow: none;
    color: #cccccc;
}

.caret {
    padding-right: 15px;
}

#SendEmbed-ChannelSelect input[type="text"]:not(.browser-default) {
    border-bottom: none;
    margin-bottom: 0;
    padding: 0 10px 0 10px;
}

.select-wrapper:not(.md-outline) .select-dropdown:focus {
    border-bottom: transparent !important;
}

.switch label .lever {
    background: #3c3c3c;
}

.switch label input[type="checkbox"]:checked + .lever {
    background: #5c5c5c;
}


.switch label input[type="checkbox"]:checked + .lever::after {
    background: rgba(164, 38, 0, 0.932);
}

.switch label .lever::after {
    background: #555555;
}