.AddTracker-GuildIcon {
    width: 35px;
    margin-right: 5%;
}

.AddTracker-Dropdown-Item-Span {
    color: #cccccc;
    font-weight: 600;
}

.AddTracker-DropDown {
    width: 200px
}

.dropdown-content {
    background: #3B3B3B;
    border-color: #3B3B3B;
}

.dropdown-content li:hover, .dropdown-content li.active {
    background: #1a1a1a;
}

.dropdown-content li > a, .dropdown-content li > span {
    color: #cccccc;
    font-weight: 600;
}

#AddTracker-Input-Col > div, #AddTracker-Output-Col > div {
    margin: 0;
}

.caret { color: #cccccc !important; }
.select-dropdown { color: #cccccc !important; }
.select-dropdown li.disabled, .select-dropdown li.disabled > span, .select-dropdown li.optgroup {
    color: orange;
    font-weight: 600;
}