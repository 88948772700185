#Playlists {
    height: 50vh;
    width: 100%;
    background: #151515;
};

#Playlists-ContainerMain {
    background: transparent;
}

.Playlists-Container {
    margin-top: 280px;
}

#Playlists-MyPlaylists, #Playlists-GuildPlaylists {
    background: #363636 !important;
    border: solid #363636;
    font-weight: 600;
    text-align: center;
    padding: 20px 80px 20px 80px;
    
    border-radius: 2px;
    transition: .5s;
}

#Playlists-MyPlaylists:hover, #Playlists-GuildPlaylists:hover {
    border: medium solid orange;
    color: orange;
}

#Playlists-MyPlaylists {

}

#Playlists-GuildPlaylists {

}